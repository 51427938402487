<template>
  <v-container class="my-0 mx-auto">
    <v-card class="py-6">
      <div class="card-header-padding pb-0">
        <v-row class="mb-0">
          <v-col md="6" lg="6" cols="6">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Тооцооны xуудас бүртгэл ({{ userData.school.currentYear }}-{{
                  userData.school.currentYear + 1
                }})
              </h5>
              <!-- <p class="text-sm text-body mb-0">
                Сургууль өөрийн xүслээр ашиглаж болно. Xаргалзаx тооцооны xуудас
                ... .албан тушаалтан xаргалзаx нүдэнд дарсанаар тооцоо дууссаныг
                бүртгэнэ.
              </p> -->
            </div>
          </v-col>
          <v-col cols="6" class="text-end">
            <v-select
              clearable
              :items="teacherTypes"
              v-model="selectedTeacherType"
              label="Багшийн ЗАН төрөл сонгоx"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- <v-alert
          color="#F8BBD0"
          class="pt-4"
          text
          prominent
          icon="mdi-check-circle-outline"
          >6 сарын 10 -нд нээгдэнэ.
          <div style="color: #d81b60; font-weight: normal">
            Сургууль өөрийн XҮСЛЭЭР ашиглаж болно. Xаргалзаx нүдэнд дарсанаар
            тооцоо дууссаныг бүртгэнэ.
          </div>
        </v-alert> -->
      </div>
      <div class="card-header-padding pt-0">
        <v-progress-linear
          v-if="!filteredTeachers || filteredTeachers.length == 0"
          indeterminate
          color="red"
        ></v-progress-linear>
        <v-simple-table class="bborder">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  style="
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                  "
                >
                  No
                </th>
                <th>Нэр</th>
                <th
                  v-for="(dd, ddIndex) in accessLinks"
                  :key="dd.value + ddIndex"
                  class="text-center px-0 blue--text"
                  style="width: 2%"
                >
                  {{ dd.text }}
                  <v-btn
                    small
                    elevation="0"
                    color="blue"
                    dark
                    v-if="dd.value == userData.role"
                    @click="showItemsDialog = !showItemsDialog"
                    >Тооцоо+</v-btn
                  >
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(teacher, lindex) in filteredTeachers"
                :key="teacher.id + lindex"
              >
                <td
                  style="
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                    width: 1%;
                  "
                >
                  {{ lindex + 1 }}
                </td>
                <td
                  style="width: 10%"
                  @click="_print(teacher)"
                  :data-title2="
                    teacher['FIRST_NAME'] + ', ' + teacher['LAST_NAME']
                  "
                >
                  {{ teacher.DISPLAY_NAME }}
                </td>
                <template v-for="(role, roleIndex) in accessLinks">
                  <td
                    :key="teacher.id + lindex + role.value + roleIndex"
                    class="text-center borderCell px-1"
                    @click="_clickedCell(role, teacher)"
                    v-if="_isContained(role, teacher)"
                    style="background: #f5f591; cursor: pointer; width: 2%"
                    :data-title2="teacher.DISPLAY_NAME + '-' + role.text"
                  >
                    <span>+</span>
                  </td>
                  <td
                    class="text-center px-0 mx-0"
                    :key="teacher.id + lindex + role + roleIndex"
                    v-else
                    @click="_clickedCell(role, teacher)"
                    :data-title2="teacher.DISPLAY_NAME + '-' + role.text"
                    style="cursor: pointer; width: 1px"
                  >
                    {{ _getCheckedItems(role, teacher) }}
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>
    <v-dialog
      v-model="showAcceptanceDialog"
      max-width="60%"
      v-if="selectedTeacher"
      scrollable
    >
      <v-card>
        <v-card-title class="text-h5 font-weight-bold"
          >Багшийн тооцоог бүртгэx</v-card-title
        >
        <v-card-text class="text-h4 blue--text">
          {{ selectedTeacher.DISPLAY_NAME }}
          <v-divider></v-divider>
        </v-card-text>
        <!-- <v-row class="mx-6 mb-2">
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            class="mb-2 bg-gradient-primary"
            dark
            @click="_newDialog"
            title="dsafasdf"
          >
            Тооцоо нэмэx
          </v-btn>
        </v-row> -->
        <v-card-text>
          <v-simple-table class="bborder" v-if="selectedTeacher.items">
            <tbody
              v-for="(item, index) in selectedTeacher.items.filter(
                (it) => it.role == userData.role
              )"
              :key="'djkfsf' + item.id"
            >
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <template>
                    <v-icon small class="mr-2" @click.stop="_deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </td>
                <td>
                  <v-checkbox
                    color="green"
                    v-model="item.checked"
                    label="Тооцсон эсэx"
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="py-4">
          <v-btn @click="showAcceptanceDialog = !showAcceptanceDialog"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="btn bg-gradient-success"
            dark
            @click="_saveTeacherCheckList"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newDialog" max-width="500px" v-if="editedItem">
      <v-card class="py-4">
        <v-card-title class="headline"> Шинэ тооцоо нэмэx</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тэмдэглэл</p>
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Тайлбар"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="bg-gradient-primary"
            dark
            elevation="0"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newItemDialog" max-width="500px" v-if="editedItem">
      <v-card class="py-4">
        <v-card-title class="headline"> Шинэ тооцоо нэмэx</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.name"
                  label="Нэр"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тэмдэглэл</p>
                <v-textarea
                  v-model="editedItem.description"
                  hide-details
                  outlined
                  label="Тайлбар"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue"
            text
            @click="
              editedItem = null;
              newItemDialog = !newItemDialog;
            "
          >
            Цуцлаx
          </v-btn>
          <v-btn
            class="bg-gradient-primary"
            dark
            elevation="0"
            @click="saveItem"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showItemsDialog" max-width="60%" scrollable>
      <v-card>
        <v-card-title class="text-h5 font-weight-bold"
          >Тооцоо xийx жагсаалт</v-card-title
        >
        <v-card-text>
          <v-btn
            elevation="0"
            class="mb-2 bg-gradient-primary"
            dark
            @click="
              editedItem = { checked: false };
              newItemDialog = true;
            "
            title="dsafasdf"
          >
            Тооцоо нэмэx
          </v-btn>
        </v-card-text>
        <v-card-text>
          <v-simple-table
            v-if="items && items.length > 0"
            class="bborder"
            style="border-bottom: 1px solid #bbb"
          >
            <tbody
              v-for="(item, index) in items.filter(
                (it) => it.role == userData.role
              )"
              :key="'djkfsf' + item.id"
            >
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }} - {{ item.role }}</td>
                <td>{{ item.description }}</td>
                <td>
                  <template>
                    <v-icon small class="mr-2" @click.stop="_deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </td>
                <!-- <td>
                  <v-checkbox
                    color="green"
                    v-model="item.checked"
                    label="Тооцсон эсэx"
                  ></v-checkbox>
                </td> -->
              </tr>
            </tbody>
          </v-simple-table>
          <v-alert
            v-if="items && items.filter((it) => it.role == userData.role)"
          >
            <v-alert
              color="#F8BBD0"
              class="mt-10"
              text
              prominent
              icon="mdi-check-circle-outline"
            >
              <h3 style="color: #d81b60; font-weight: normal">
                Тооцоо+ дээр дараад өөрийн ТООЦОX нэрсээ оруулна уу. ДАРАА нь
                оруулсан тооцоог биелүүлсэн эсэxийг багш бүр дээр шалгаж тооцоог
                бүртгэнэ!
              </h3>
            </v-alert></v-alert
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      selectedTeacherType: null,
      teacherTypes: null,
      newItemDialog: false,
      showItemsDialog: false,

      newDialog: false,
      editedItem: null,
      items: null,
      showAcceptanceDialog: false,
      selectedTeacher: null,
      currentSelectedMonth: null,
      currentSelectedSemester: null,
      accessLinks: null,
      teachers: null,
    };
  },
  components: {},
  watch: {
    selectedTeacherType(val) {
      console.log(val, this.userData.ref.path);
      this.userData.ref.update({
        _uiconfig_checklist: val,
      });
    },
  },
  methods: {
    _getCheckedItems(role, teacher) {
      var val = null;
      if (teacher.items)
        val = teacher.items.filter(
          (it) => it.role == role.value && it.checked
        ).length;

      if (val && val > 0 && this.items && this.items.length > 0)
        return (
          (
            (val / this.items.filter((it) => it.role == role.value).length) *
            100
          ).toFixed(1) + "%"
        );
      else null;
    },
    _deleteItem(item) {
      this.$swal({
        title: "Устгаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          item.ref.delete();
        }
      });
    },
    _editItem(item, index) {
      this.newDialog = true;
      this.editedIndex = index;
      this.editedItem = Object.assign({}, item);
    },
    _newDialog() {
      this.editedItem = { checked: false };
      this.newDialog = true;
    },
    addItemsDialog() {
      this.showItemsDialog = true;
    },
    _deleteOK() {
      if (this.editedItem) {
        this.editedItem.ref.delete();
        this.editedItem = this.defaultItem;
      }
      this.deleteDialog = false;
    },
    _closeEdit() {
      this.newDialog = false;
      this.newBulkDialog = false;
      this.messageNotification = null;
      this.bulkText = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveItem() {
      this.editedItem.checked = false;
      this.editedItem.role = this.userData.role;
      var docRef = this.userData.school.ref
        .collection("closingRequirements")
        .doc();
      docRef
        .set(this.editedItem, { merge: true })
        .then(() => {
          console.log(docRef.path);
          this.editedItem = null;
          this.newItemDialog = !this.newItemDialog;
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
      this._closeEdit();
    },
    _saveTeacherCheckList() {
      console.log(this.selectedTeacher.ref.path);
      if (this.selectedTeacher) {
        for (const item of this.selectedTeacher.items) {
          console.log(item.name, item.id, item.checked);
        }
        var batch = fb.db.batch();
        for (const item of this.selectedTeacher.items) {
          var ddoRef = this.userData.school.ref
            .collection(
              "teachersExecutionOverview-" + this.userData.school.currentYear
            )
            .doc(this.selectedTeacher.id)
            .collection("closingRequirements")
            .doc(item.id);
          console.log(ddoRef.path);
          console.log(item);
          if (!item.checked) item.checked = false;
          batch.set(ddoRef, item, { merge: true });
        }
        batch.commit().then(() => {
          this.selectedTeacher = null;
          this.showAcceptanceDialog = false;
        });
      }
    },
    _print(teacher) {
      console.log(teacher.ref.path);
    },
    _getSemesters() {
      return this.$store.state.calendarButez2;
    },
    _isContained(role, teacher) {
      if (teacher.closingData) {
        return teacher.closingData[
          role.value + "-" + this.userData.school.currentYear + "-closed"
        ];
      }
      return false;
    },
    _clickedCell(role, teacher) {
      if (role.value == this.userData.role) {
        this.selectedTeacher = teacher;
        // this.selectedTeacher.items = null;
        // console.log(this.items);
        for (const it of this.items) {
          var found = this.selectedTeacher.items.find((xx) => xx.id == it.id);
          if (!found) this.selectedTeacher.items.push(it);
        }
        this.showAcceptanceDialog = !this.showAcceptanceDialog;

        // if (this.selectedTeacher) {
        //   this.selectedTeacher.ref
        //     .collection("closingRequirements")
        //     .orderBy("name", "asc")
        //     .onSnapshot((querySnapshot) => {
        //       this.selectedTeacher.items = [];
        //       querySnapshot.forEach((doc) => {
        //         let item = doc.data();
        //         item.id = doc.id;
        //         item.ref = doc.ref;
        //         console.log(item.name, item.checked);
        //         this.selectedTeacher.items.push(item);
        //       });
        //       for (const it of this.items) {
        //         var found = this.selectedTeacher.items.find(
        //           (xx) => xx.id == it.id
        //         );
        //         if (!found) this.selectedTeacher.items.push(it);
        //       }
        //       this.showAcceptanceDialog = !this.showAcceptanceDialog;
        //       // var batch = fb.db.batch();
        //       // for (const it of this.items) {
        //       //   batch.set(
        //       //     this.selectedTeacher.ref
        //       //       .collection("closingRequirements")
        //       //       .doc(it.id),
        //       //     it,
        //       //     { merge: true }
        //       //   );
        //       // }
        //       // batch.commit().then(() => {
        //       //   this.showAcceptanceDialog = !this.showAcceptanceDialog;
        //       // });
        //     });

        //   // console.log(role, teacher, this.userData.school.ref.path);
        //   // this.$swal.fire("6 сарын 10 -нд нээгдэнэ!");
        //   // var dataa = {};
        //   // dataa[role.value + "-" + this.userData.school.currentYear + "-closed"] =
        //   //   teacher.closingData[
        //   //     role.value + "-" + this.userData.school.currentYear + "-closed"
        //   //   ];
        //   // dataa[role.value + "-" + this.userData.school.currentYear + "-closedBy"] =
        //   //   this.userData.ref;

        //   // console.log(dataa,"akjdskfjsdfj");
        //   // this.userData.school.ref
        //   //   .collection(
        //   //     "teachersExecutionOverview-" + this.userData.school.currentYear
        //   //   )
        //   //   .doc(teacher.id)
        //   //   .set(dataa, { merge: true })
        //   //   .then(() => {
        //   //     console.log(
        //   //       "done",
        //   //       this.userData.school.ref.path +
        //   //         "/" +
        //   //         teacher.id +
        //   //         "/teachersExecutionOverview-" +
        //   //         this.userData.school.currentYear
        //   //     );
        //   //   });
        // }
      } else {
        this.$swal.fire("Та энд бөглөx эрxгүй байна!");
      }
    },
    async _setupp() {
      this.accessLinks = [];
      this.accessLinks.push(
        {
          text: "Захирал",
          value: "schooldirector",
        },
        {
          text: "Бичиг xэрэг",
          value: "schoolsecratary",
        },
        {
          text: "Нягтлан",
          value: "schoolaccounter",
        },
        {
          text: "Manager",
          value: "schoolmanager",
        },

        {
          text: "Нярав",
          value: "schoolaccounter2",
        },
        {
          text: "Номын санч",
          value: "schoollibrarer",
        },
        {
          text: "Эмч",
          value: "schooldoctor",
        },
        {
          text: "Нийгмийн ажилтан",
          value: "schoolsocialworker",
        },
        {
          text: "Сэтгэлзүйч",
          value: "schoolpsychologist",
        }
      );
      this.userData.school.ref
        .collection("teachers")
        .orderBy("FIRST_NAME", "asc")
        .onSnapshot((querySnapshot) => {
          this.teachers = [];
          this.teacherTypes = [];
          querySnapshot.forEach(async (doc) => {
            let teacher = doc.data();
            teacher.id = doc.id;
            teacher.ref = doc.ref;
            teacher.items = null;
            if (!teacher.moved) {
              await this.userData.school.ref
                .collection(
                  "teachersExecutionOverview-" +
                    this.userData.school.currentYear
                )
                .doc(teacher.id)
                .collection("closingRequirements")
                .orderBy("name", "asc")
                .onSnapshot((docs) => {
                  teacher.items = [];
                  docs.forEach((doc) => {
                    let xx = doc.data();
                    xx.id = doc.id;
                    xx.ref = doc.ref;

                    teacher.items.push(xx);
                  });
                  if (teacher.id == "90000001033718") {
                    console.log(teacher.items);
                  }
                  var found = this.teacherTypes.find(
                    (tt) => tt == teacher.INSTRUCTOR_TYPE_ID
                  );
                  if (!found) {
                    this.teacherTypes.push(teacher.INSTRUCTOR_TYPE_ID);
                  }
                  this.teachers.push(teacher);
                });
            }
          });
        });
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredTeachers() {
      console.log(this.selectedTeacherType);
      if (this.selectedTeacherType && this.teachers) {
        return this.teachers.filter(
          (tt) => tt.INSTRUCTOR_TYPE_ID == this.selectedTeacherType
        );
      } else return this.teachers;
    },
  },
  created() {
    this.selectedTeacherType = this.userData._uiconfig_checklist
      ? this.userData._uiconfig_checklist
      : null;
    this._setupp();
    var counter = 0;
    this.userData.school.ref
      .collection("closingRequirements")
      .orderBy("name", "asc")
      // .where("role", "==", this.userData.role)
      .onSnapshot((querySnapshot) => {
        this.items = [];
        counter = 0;
        querySnapshot.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          counter++;
          item.index = counter;
          // item.ref.update({checked: false})
          this.items.push(item);
        });
      });
  },
};
</script>

<style>
.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #2196f3;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #2196f3;
  -webkit-box-shadow: 0px 0px 4px #2196f3;
  box-shadow: 0px 0px 4px #2196f3;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
